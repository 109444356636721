<template>
	<div v-if="!loading">
		<ve-table
			:rows="rows"
			:columns="columns"
			:onCellClick="onCellClick"
			:onRowDoubleClick="onRowDoubleClick"
			:sort-options="sortOptions"
		>
		</ve-table>
	</div>
</template>

<script>
import Store from '@/store'
import { useQuery } from '@vue/apollo-composable'
import { onMounted, reactive, ref } from '@vue/composition-api'
import storeSelectCompany from '@/_srcv2/pages/transactions/add-transaction/scripts/useSelectCompanyForm.js'
import GetActiveCustomersForPaymentQuery from '@/_srcv2/pages/transactions/add-transaction/graphql/GetActiveCustomersForPaymentQuery.graphql'
import useSelectedInvoiceState from '@/_srcv2/pages/transactions/add-transaction/scripts/useSelectedInvoiceState.js'
import useTransactionAddEditForm from '@/_srcv2/pages/transactions/add-transaction/scripts/useTransactionAddEditForm.js'

export default {
	name: 'SearchCompanyForTransaction',
	setup() {
		const { columns } = storeSelectCompany()
		const rows = ref([])
		const sortOptions = {
			enabled: true,
			initialSortBy: { field: 'customer_title', type: 'asc' },
		}
		// * ---------------------------------------------------------
		const options = reactive({
			fetchPolicy: 'no-cache',
		})
		const { refetch: refetchCustomers, loading } = useQuery(
			GetActiveCustomersForPaymentQuery,
			() => ({
				company_id: Store.getters.getUserCurrentCompany,
			}),
			options,
		)
		const refetchData = () => {
			refetchCustomers().then((result) => {
				rows.value = result.data.customers
				console.log('rows.value', rows.value)
			})
		}
		onMounted(() => {
			refetchData()
		})
		// * -----------------------------------------------------------------
		const { selectedInvoiceState } = useSelectedInvoiceState()
		const { transactionFormState } = useTransactionAddEditForm()
		const storeSelectedCompanyDetails = (item) => {
			return new Promise((resolve, reject) => {
				if (item !== undefined) {
					console.log('selectedCustomerId', item.customer_id)
					selectedInvoiceState.invoiceOurCompany =
						Store.getters.getUserCurrentCompany
					selectedInvoiceState.customerId = item.customer_id
					selectedInvoiceState.company =
						item.customer_nickname +
						' - ' +
						item.customer_title +
						' - ' +
						item.customer_org_num
					selectedInvoiceState.invoiceNumber = 'Utan Faktura'
					selectedInvoiceState.transactionType = ''
					selectedInvoiceState.invoiceType = ''
					selectedInvoiceState.isInExchange = false
					selectedInvoiceState.invoiceCurrency = 'kr.'
					// *------------------------------------------------------------
					selectedInvoiceState.invoiceTotal = 0
					selectedInvoiceState.paymentTotal = 0
					selectedInvoiceState.balanceTotal = Number.MAX_SAFE_INTEGER
					// *------------------------------------------------------------
					selectedInvoiceState.invoiceTotalInExchange = 0
					selectedInvoiceState.paymentTotalInExchange = 0
					selectedInvoiceState.balanceTotalInExchange = Number.MAX_SAFE_INTEGER
					// *------------------------------------------------------------
					transactionFormState.paymentCredit = Number.MAX_SAFE_INTEGER
					// *------------------------------------------------------------
					selectedInvoiceState.balanceTotalDb = Number.MAX_SAFE_INTEGER
					// Hide Search Component *******************************************************
					// transactionFormState.showCompanySearchBox = false
					// Set search finished to open next form ***************************************
					transactionFormState.searchFinished = true
					// transactionFormState.showCloseButton = false
					// Refresh Transaction Form
					Store.dispatch(
						'setKeyTransactionForm',
						Store.getters.getKeyTransactionForm + 1,
					)
					resolve(item)
				} else {
					reject('Something is wrong')
				}
			})
		}
		const onCellClick = (params) => {
			if (params.column.field === 'select') {
				console.log('params.row', params.row)
				storeSelectedCompanyDetails(params.row)
			}
		}
		const onRowDoubleClick = (params) => {
			storeSelectedCompanyDetails(params.row)
		}
		return {
			columns,
			rows,
			onCellClick,
			onRowDoubleClick,
			sortOptions,
			loading,
		}
	},
}
</script>

<style scoped></style>
