<!-- @format -->

<template>
	<layout-wide>
		<div class="margin-bottom-30">
			<sub-navbar page="Transaction" />
			<div>
				Transaction Type:
				<span style="font-weight: 700; color: red">{{ transactionType }}</span>
			</div>
			<div>
				Payment Type:
				<span style="font-weight: 700; color: green">{{ paymentType }}</span>
			</div>
			<div>Transaction Date: {{ $store.getters.getTransactionDate }}</div>
			<section>
				<b-field label="Select transaction date">
					<b-datepicker
						v-model="selected"
						:show-week-number="showWeekNumber"
						:locale="locale"
						:first-day-of-week="firstDayOfWeek"
						placeholder="Click to select..."
						icon="calendar-today"
						trap-focus
					>
					</b-datepicker>
				</b-field>
			</section>
			<b-button
				class="is-primary margin-top-15"
				@click="onClose"
				v-if="transactionFormState.showCloseButton"
			>
				X</b-button
			>
			<div>
				<select-transaction-process />
			</div>
			<div v-if="transactionFormState.showInvoiceSearchBox">
				<search-invoice-for-transaction />
			</div>
			<div v-if="transactionFormState.showCompanySearchBox">
				<search-company-for-transaction />
			</div>
			<div v-if="false">
				<add-transaction-form-frame />
			</div>
			<div v-if="transactionFormState.searchFinished">
				<VPopup :closePopup="onClose">
					<div slot="header">
						Add New Payment - Fill the form to enter new payment details
					</div>
					<div slot="body"><add-transaction-form-frame /></div>
					<div slot="footer"></div>
				</VPopup>
			</div>
		</div>
	</layout-wide>
</template>

<script>
import Store from '@/store'
import dateformat from 'dateformat'
import { onMounted, ref, watchEffect } from '@vue/composition-api'
// import components
import SubNavbar from '@/_srcv2/components/_shared/sub-navbar/SubNavbar'
import AddTransactionFormFrame from '@/_srcv2/pages/transactions/add-transaction/subcomponents/AddTransactionFormFrame.vue'
import SearchInvoiceForTransaction from '@/_srcv2/pages/transactions/add-transaction/subcomponents/SearchInvoiceForTransaction.vue'
import SelectTransactionProcess from '@/_srcv2/pages/transactions/add-transaction/subcomponents/SelectTransactionProcess.vue'
import SearchCompanyForTransaction from '@/_srcv2/pages/transactions/add-transaction/subcomponents/SearchCompanyForTransaction.vue'
// import scripts
import useTransactionAddEditForm from '@/_srcv2/pages/transactions/add-transaction/scripts/useTransactionAddEditForm.js'
// import useSearchBoxState from '@/_srcv2/pages/transactions/add-transaction/scripts/useSearchBoxState.js'
import VPopup from '@/_srcv2/pages/admin/edit-stock-prices/sub-components/VPopup.vue'
export default {
	name: 'AddTransaction',
	components: {
		VPopup,
		SubNavbar,
		SearchInvoiceForTransaction,
		SearchCompanyForTransaction,
		AddTransactionFormFrame,
		SelectTransactionProcess,
	},
	setup() {
		const firstDayOfWeek = 1
		onMounted(() => {
			onClose()
		})
		// --------------------------------------------------------------------------------------------
		const {
			transactionFormState,
			modelTransaction,
			transactionType,
			paymentType,
		} = useTransactionAddEditForm()
		// const { searchFinished } = useSearchBoxState()
		const onClose = () => {
			transactionFormState.showInvoiceSearchBox = false
			transactionFormState.showCompanySearchBox = false
			transactionFormState.showCloseButton = false
			transactionFormState.showButtons = true
			// ---------------------------------
			transactionFormState.searchFinished = false
		}
		// ---------------------------------------------------
		const selected = ref(new Date())
		watchEffect(() => {
			Store.dispatch(
				'setTransactionDate',
				dateformat(selected.value, 'yyyy-mm-dd'),
			)
			modelTransaction.paymentDate = dateformat(selected.value, 'yyyy-mm-dd')
		})
		const showWeekNumber = false
		const locale = 'sv-SE'
		return {
			firstDayOfWeek,
			transactionFormState,
			onClose,
			// searchFinished,
			selected,
			showWeekNumber,
			locale,
			transactionType,
			paymentType,
		}
	},
}
</script>

<style scoped>
.margin-top-15 {
	margin-top: 15px;
}
</style>
