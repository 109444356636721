<template>
	<div>
		<section>
			<span class="header-tag"
				>Invoice Total:
				<span class="color-blue">{{ selectedInvoiceState.invoiceTotal }}</span>
			</span>
			<br />
			<span class="header-tag"
				>Payment Total:
				<span class="color-green">{{
					selectedInvoiceState.paymentTotal
				}}</span> </span
			><br />
			<span class="header-tag"
				>Invoice Balance:
				<span class="color-red">{{
					selectedInvoiceState.balanceTotal
				}}</span></span
			>
			<br /><br />
		</section>
		<section v-if="selectedInvoiceState.invoiceCurrency !== 'kr.'">
			<span class="header-tag"
				>Invoice Total:
				<span class="color-blue">
					{{ selectedInvoiceState.invoiceTotalInExchange }}
				</span>
			</span>
			<br />
			<span class="header-tag"
				>Payment Total:
				<span class="color-green">
					{{ selectedInvoiceState.paymentTotalInExchange }}
				</span>
			</span>
			<br />
			<span class="header-tag"
				>Invoice Balance:
				<span class="color-red">
					{{ selectedInvoiceState.balanceTotalInExchange }}
				</span>
			</span>
			<br /><br />
		</section>
		<div>
			<transaction-form :key="$store.getters.getKeyTransactionForm" />
		</div>
	</div>
</template>

<script>
import useSelectedInvoiceState from '@/_srcv2/pages/transactions/add-transaction/scripts/useSelectedInvoiceState.js'
import TransactionForm from '@/_srcv2/pages/transactions/add-transaction/subcomponents/TransactionForm.vue'

export default {
	name: 'AddTransactionFormFrame',
	components: {
		TransactionForm,
	},
	setup() {
		const { selectedInvoiceState } = useSelectedInvoiceState()
		return {
			selectedInvoiceState,
		}
	},
}
</script>

<style scoped>
.header-tag {
	font-weight: bold;
}
.color-orange {
	color: orange;
}
.color-blue {
	color: blue;
}
.color-red {
	color: red;
}
.color-green {
	color: green;
}
</style>
