/** @format */

import Vue from 'vue'
import VueCompositionApi, {
	getCurrentInstance,
	// reactive
} from '@vue/composition-api'
Vue.use(VueCompositionApi)
// import dateformat from 'dateformat'
import numeral from 'numeral'

const getPaymentAbbreviation = (item) => {
	switch (item) {
		case 'collect bank':
			return 'BT'
		case 'collect bank in advance':
			return 'BT'
		case 'collect bank reminder fee':
			return 'BT'
		case 'collect cash':
			return 'CT'
		case 'collect cash in advance':
			return 'CT'
		case 'collect cash reminder fee':
			return 'CT'
		case 'setoff':
			return 'SO'
		case 'set off credit invoice':
			return 'SO'
		case 'set off advanced payment':
			return 'SO'
		default:
			return '??'
	}
}
const getTransactionType = (payload) => {
	console.log('payload', payload)
	switch (payload) {
		case 'BT':
			return 'till bankkonto'
		case 'CT':
			return 'kontant'
		case 'SO':
			return 'Övriga'
		default:
			return '??'
	}
}
// Create uuid
const vm = getCurrentInstance()
const NAMESPACE = '96e1b132-5765-5e15-b9a8-8a6cec456dda'

function getUUID() {
	let TIMEPARAMETER = (
		Date.now() + Math.floor(Math.random() * 1000000000000000000).toString()
	).toString()
	return vm.$uuid.v5(TIMEPARAMETER, NAMESPACE)
}
// Get date and format it
// const getNewDateWithTime = () => {
//   let documentDateFromSystem = new Date()
//   return dateformat(documentDateFromSystem, 'yyyy-mm-dd HH:MM')
// }
// format numbers
// format number from database to table with two decimal
const numberFromDataBaseToTable = (number) => {
	const correctedNumber = number / 100
	return numeral(correctedNumber).format('0,0.00')
}
const roundedReportNumber = (numberToReport) => {
	return Math.round(numberToReport / 100)
}
const addCurrency = (number, currency = 'kr.') => {
	return numeral(number).format() + ' ' + currency
}
const roundedCurrencyAddedNumber = (number, currency) => {
	return addCurrency(roundedReportNumber(number), currency)
}
// todo ---------------------------------------------------------------
const addInvoiceFeeToPayment = (payload) => {
	let feeToPay = 0
	if (payload.invoice_type === 'Inland') {
		feeToPay = payload.is_fee_addable
			? payload.paper_invoice_fee + payload.paper_invoice_fee_vat
			: 0
	}
	return feeToPay
}
// ? -------------------  Invoice related functions ---------------------
const getSalesInvoiceTotal = (payload) => {
	return roundedReportNumber(
		payload.invoice_totals_aggregate.aggregate.sum.line_price_total_credit +
			payload.invoice_totals_aggregate.aggregate.sum.vat_credit +
			addInvoiceFeeToPayment(payload),
	)
}
const getSalesInvoiceTotalExc = (payload) => {
	return roundedReportNumber(
		payload.invoice_totals_aggregate.aggregate.sum
			.line_price_total_credit_exchange +
			payload.invoice_totals_aggregate.aggregate.sum.vat_credit_exchange,
	)
}
const getPurchaseInvoiceTotal = (payload) => {
	return roundedReportNumber(
		payload.invoice_totals_aggregate.aggregate.sum.line_price_total_debit +
			payload.invoice_totals_aggregate.aggregate.sum.vat_debit,
	)
}
const getPurchaseInvoiceTotalExc = (payload) => {
	return roundedReportNumber(
		payload.invoice_totals_aggregate.aggregate.sum
			.line_price_total_debit_exchange +
			payload.invoice_totals_aggregate.aggregate.sum.vat_debit_exchange,
	)
}
// ---------------------------------------------------------------------------------------------------
const invoiceToPay = (payload) => {
	let correctedToPay
	let invoiceTotalDb
	if (payload.transaction_type === 'sales invoice ') {
		correctedToPay = addCurrency(getSalesInvoiceTotal(payload))
		invoiceTotalDb = getSalesInvoiceTotal(payload).toFixed(2)
	} else {
		correctedToPay = addCurrency(getPurchaseInvoiceTotal(payload))
		invoiceTotalDb = getPurchaseInvoiceTotal(payload).toFixed(2)
	}
	return { correctedToPay, invoiceTotalDb }
}
const invoiceToPayExchange = (payload) => {
	let correctedToPay
	let invoiceTotalDb
	if (payload.transaction_type === 'sales invoice ') {
		correctedToPay = addCurrency(
			getSalesInvoiceTotalExc(payload),
			payload.invoice_exchange_unit,
		)
		invoiceTotalDb = getSalesInvoiceTotalExc(payload).toFixed(2)
	} else {
		correctedToPay = addCurrency(
			getPurchaseInvoiceTotalExc(payload),
			payload.invoice_exchange_unit,
		)
		invoiceTotalDb = getPurchaseInvoiceTotalExc(payload).toFixed(2)
	}
	return { correctedToPay, invoiceTotalDb }
}
// -------------------------------------------------------------------------------------------------------
const invoicePayment = (payload) => {
	const salesInvoicePayment =
		payload.invoice_payments_aggregate.aggregate.sum.payment_credit || 0
	const purchaseInvoicePayment =
		payload.invoice_payments_aggregate.aggregate.sum.payment_debit || 0
	let correctedPayment
	let paymentDb
	if (payload.transaction_type === 'sales invoice ') {
		correctedPayment = addCurrency((salesInvoicePayment / 100).toFixed(2))
		paymentDb = (salesInvoicePayment / 100).toFixed(2)
	} else {
		correctedPayment = addCurrency((purchaseInvoicePayment / 100).toFixed(2))
		paymentDb = (purchaseInvoicePayment / 100).toFixed(2)
	}
	return {
		correctedPayment,
		paymentDb,
	}
}
const invoicePaymentExchange = (payload) => {
	const salesInvoicePayment =
		payload.invoice_payments_aggregate.aggregate.sum
			.payment_credit_in_exchange || 0
	const purchaseInvoicePayment =
		payload.invoice_payments_aggregate.aggregate.sum
			.payment_debit_in_exchange || 0
	let correctedPayment
	let paymentDb
	if (payload.transaction_type === 'sales invoice ') {
		correctedPayment = addCurrency(
			(salesInvoicePayment / 100).toFixed(2),
			payload.invoice_exchange_unit,
		)
		paymentDb = (salesInvoicePayment / 100).toFixed(2)
	} else {
		correctedPayment = addCurrency(
			(purchaseInvoicePayment / 100).toFixed(2),
			payload.invoice_exchange_unit,
		)
		paymentDb = (purchaseInvoicePayment / 100).toFixed(2)
	}
	return {
		correctedPayment,
		paymentDb,
	}
}
// --------------------------------------------------------------------------------------------
const invoiceBalance = (payload) => {
	const toPay = Math.round(
		(payload.invoice_totals_aggregate.aggregate.sum.line_price_total_credit +
			payload.invoice_totals_aggregate.aggregate.sum.vat_credit +
			addInvoiceFeeToPayment(payload)) /
			100,
	).toFixed(2)
	const payment =
		(
			payload.invoice_payments_aggregate.aggregate.sum.payment_credit / 100
		).toFixed(2) || 0
	const saleInvoiceBalance = (toPay - payment).toFixed(2)
	const correctedBalance = addCurrency(saleInvoiceBalance)
	const invoiceBalanceForReminder = Math.round(saleInvoiceBalance / 100) * 100
	return {
		correctedBalance,
		balanceDb: saleInvoiceBalance,
		invoiceBalanceForReminder,
	}
}
const invoiceBalanceExchange = (payload) => {
	const toPay = Math.round(
		(payload.invoice_totals_aggregate.aggregate.sum
			.line_price_total_credit_exchange +
			payload.invoice_totals_aggregate.aggregate.sum.vat_credit_exchange) /
			100,
	).toFixed(2)
	const payment =
		(
			payload.invoice_payments_aggregate.aggregate.sum
				.payment_credit_in_exchange / 100
		).toFixed(2) || 0
	const saleInvoiceBalance = (toPay - payment).toFixed(2)
	const correctedBalance = addCurrency(
		saleInvoiceBalance,
		payload.invoice_exchange_unit,
	)
	const invoiceBalanceForReminder = Math.round(saleInvoiceBalance / 100) * 100
	return {
		correctedBalance,
		balanceDb: saleInvoiceBalance,
		invoiceBalanceForReminder,
	}
}
// -----------------------------------------------------------------------------------------
export default function useAddEditTransactionStore() {
	return {
		getPaymentAbbreviation,
		getUUID,
		numberFromDataBaseToTable,
		roundedCurrencyAddedNumber,
		getTransactionType,
		invoiceToPay,
		invoicePayment,
		invoiceBalance,
		invoiceToPayExchange,
		invoicePaymentExchange,
		invoiceBalanceExchange,
	}
}
