<!-- @format -->

<template>
	<div>
		<div v-if="loading" style="font-weight: 900; color: red">...Loading</div>
		<div v-if="!loading">
			Search Invoice For Transaction - Currency: sek <br />
			<div style="font-weight: 900">
				<span class="text-red">Between the dates:</span> &nbsp;
				{{ beginningDate }} &nbsp; and &nbsp;
				{{ endingDate }}
				<div style="margin-top: 20px">
					<b-button :type="buttonType" @click="toggleDays">{{
						buttonTag
					}}</b-button>
				</div>
			</div>
			<ve-table
				:columns="columns"
				:rows="unpaidInvoicesList"
				:searchedOptions="true"
				:sort-options="sortOptions"
				:onRowDoubleClick="onRowDoubleClick"
				:onCellClick="onCellClick"
			>
				<template slot="table-row" slot-scope="props">
					<span v-if="props.column.field === 'select'">
						<b-button class="is-small is-success" :disabled="isDisabled">
							Select
						</b-button>
					</span>
					<span v-else>
						{{ props.formattedRow[props.column.field] }}
					</span>
				</template>
			</ve-table>
			<download-csv :data="unpaidInvoicesList">
				<div class="margin-bottom-30 margin-top-30">
					<b-button class="is-success"> Download the List </b-button>
				</div>
			</download-csv>
		</div>
	</div>
</template>

<script>
import Store from '@/store'
import {
	onMounted,
	reactive,
	ref,
	watchEffect,
	computed,
} from '@vue/composition-api'
import { useQuery } from '@vue/apollo-composable'
import useAddEditTransactionStore from '@/_srcv2/pages/transactions/add-transaction/scripts/useAddEditTransactionStore.js'
import useSelectedInvoiceState from '@/_srcv2/pages/transactions/add-transaction/scripts/useSelectedInvoiceState.js'
import useTransactionAddEditForm from '@/_srcv2/pages/transactions/add-transaction/scripts/useTransactionAddEditForm.js'
import GetInvoicesForPaymentQuery from '@/_srcv2/pages/transactions/add-transaction/graphql/GetInvoicesForPaymentQuery.graphql'
import alarmsound from '@/assets/sounds/alarm.mp3'
import dateformat from 'dateformat'
import useUnpaidInvoicesState from '@/_srcv2/pages/transactions/add-transaction/scripts/useUnpaidInvoicesState.js'

export default {
	name: 'SearchInvoiceForTransaction',
	setup() {
		const buttonType = ref('is-success')
		// ! -----------------------------------------------------------------
		const today = ref(new Date())
		const days = ref(365)
		const buttonTag = ref('Get without time limited')
		const promiseDays = (condition) => {
			return new Promise((resolve, reject) => {
				if (condition) {
					console.log('Condition is ', condition)
					if (days.value === 365) {
						days.value = 1500
					} else {
						days.value = 365
					}
					if (buttonTag.value === 'Get without time limited') {
						buttonTag.value = 'Get last year'
					} else {
						buttonTag.value = 'Get without time limited'
					}
					if (buttonType.value === 'is-success') {
						buttonType.value = 'is-info'
					} else {
						buttonType.value = 'is-success'
					}
					resolve(true)
				} else {
					const error = new Error('Condition is false')
					reject(error)
				}
			}).catch((err) => console.log('Error: ', err))
		}

		const toggleDays = () => {
			console.log('toggle is fired')
			promiseDays(true).then(() => setInitialValues())
		}
		const beginningDate = computed(() => {
			const bd = new Date().setTime(
				today.value - days.value * 24 * 60 * 60 * 1000,
			)
			console.log('bd', bd)
			console.log('myDate', dateformat(bd, 'yyyy-mm-dd'))
			return dateformat(bd, 'yyyy-mm-dd')
		})
		const endingDate = computed(() => {
			return dateformat(today.value, 'yyyy-mm-dd')
		})
		// ! ----------------------------------------------------------------------------------------
		const sortOptions = {
			enabled: true,
			initialSortBy: { field: 'invoiceNum', type: 'asc' },
		}
		// Get data from database
		const options = reactive({
			fetchPolicy: 'cache-first',
		})
		const { refetch, loading } = useQuery(
			GetInvoicesForPaymentQuery,
			() => ({
				our_company: Store.getters.getUserCurrentCompany,
				transaction_type: 'sales invoice ',
				bd: beginningDate.value,
				ed: endingDate.value,
			}),
			options,
		)
		const { unpaidInvoicesList } = useUnpaidInvoicesState()
		const {
			invoiceToPay,
			invoicePayment,
			invoiceBalance,
			invoiceToPayExchange,
			invoicePaymentExchange,
			invoiceBalanceExchange,
		} = useAddEditTransactionStore()
		const setInitialValues = () => {
			refetch().then((result) => {
				console.log('result', result)
				unpaidInvoicesList.value = result.data.document_transactions.map(
					(item) => {
						return {
							invoiceNum: item.invoice_number,
							customerNickname: item.customer.customer_nickname,
							customerTittle: item.customer.customer_title,
							customerId: item.customer.customer_id,
							customer: `${item.customer.customer_title} - ${item.customer.customer_nickname}`,
							invoiceDate: item.invoice_date,
							invoiceDueDate: item.invoice_due_date,
							reminderNumber: item?.reminder_lines?.reminder_id ?? '',
							reminderDueDate:
								item?.reminder_lines?.reminder_header?.reminder_due_date ?? '',
							invoiceTotal: invoiceToPay(item).correctedToPay,
							payment: invoicePayment(item).correctedPayment,
							balance: invoiceBalance(item).correctedBalance,
							// --------------------------------------------------------------------------
							invoiceTotalDb: invoiceToPay(item).invoiceTotalDb,
							paymentDb: invoicePayment(item).paymentDb,
							balanceDb: invoiceBalance(item).balanceDb,
							// define *******************************************************************
							invoiceTotalExchange: invoiceToPayExchange(item).correctedToPay,
							paymentExchange: invoicePaymentExchange(item).correctedPayment,
							balanceExchange: invoiceBalanceExchange(item).correctedBalance,
							// --------------------------------------------------------------------------
							invoiceTotalExchangeDb: invoiceToPayExchange(item).invoiceTotalDb,
							paymentExchangeDb: invoicePaymentExchange(item).paymentDb,
							balanceExchangeDb: invoiceBalanceExchange(item).balanceDb,
							// --------------------------------------------------------------------------
							// end of define ***************************************************************
							inkasso: item.is_sent_in_the_bailiffs ? '*' : '',
							exchange: item.invoice_exchange_unit !== 'kr.' ? '*' : '',
							ourCompany: item.our_company,
							transactionType: item.transaction_type,
							invoiceType: item.invoice_type,
							isExchange: item.invoice_exchange_unit !== 'kr.',
							exchangeUnit: item.invoice_exchange_unit,
						}
					},
				)
			})
		}
		onMounted(() => {
			setInitialValues()
		})
		const printRows = () => {
			console.log('unpaidInvoicesList', unpaidInvoicesList.value)
		}
		const columns = [
			{
				label: 'Select',
				field: 'select',
				width: '95px',
				sortable: false,
			},
			{
				label: 'Invoice Number',
				field: 'invoiceNum',
				width: '115px',
			},
			{
				label: 'Customer',
				field: 'customer',
				width: '275px',
			},
			{
				label: 'Invoice Due Date',
				field: 'invoiceDueDate',
				width: '120px',
			},
			{
				label: 'Reminder Due Date',
				field: 'reminderDueDate',
				width: '125px',
			},
			{
				label: 'Invoice Total',
				field: 'invoiceTotal',
				width: '130px',
			},
			{
				label: 'Payment',
				field: 'payment',
				width: '130px',
			},
			{
				label: 'Balance',
				field: 'balance',
				width: '130px',
			},
			{
				label: 'EX',
				field: 'exchange',
				width: '57px',
			},
			{
				label: 'IK',
				field: 'inkasso',
				width: '55px',
			},
		]
		const setTransactionFormValues = (params) => {
			selectedInvoiceState.invoiceOurCompany =
				Store.getters.getUserCurrentCompany
			selectedInvoiceState.customerId = params.row.customerId
			selectedInvoiceState.company =
				params.row.customerTittle +
				' - ' +
				params.row.customerNickname +
				' - ' +
				params.row.customerId
			selectedInvoiceState.invoiceNumber = params.row.invoiceNum
			selectedInvoiceState.transactionType = params.row.transactionType
			selectedInvoiceState.invoiceType = params.row.invoiceType
			selectedInvoiceState.isCredit = params.row.invoiceTotal < 0
			selectedInvoiceState.isInExchange = params.row.isExchange
			selectedInvoiceState.invoiceCurrency = params.row.exchangeUnit
			// --------------------------------------------------------------
			selectedInvoiceState.invoiceTotal = params.row.invoiceTotal
			selectedInvoiceState.paymentTotal = params.row.payment
			selectedInvoiceState.balanceTotal = params.row.balance
			// --------------------------------------------------------------
			selectedInvoiceState.invoiceTotalInExchange =
				params.row.invoiceTotalExchange
			selectedInvoiceState.paymentTotalInExchange = params.row.paymentExchange
			selectedInvoiceState.balanceTotalInExchange = params.row.balanceExchange
			// ---------------------------------------------------------------------
			selectedInvoiceState.balanceTotalDb = params.row.balanceDb
			selectedInvoiceState.balanceTotalInExchangeDb =
				params.row.balanceExchangeDb
			// Hide Search Component *******************************************************
			// transactionFormState.showInvoiceSearchBox = false
			// Set search finished to open next form ***************************************
			transactionFormState.searchFinished = true
			// transactionFormState.showCloseButton = false
			// Refresh Transaction Form
			Store.dispatch(
				'setKeyTransactionForm',
				Store.getters.getKeyTransactionForm + 1,
			)
			// console.log('*** selectedInvoiceState', selectedInvoiceState)
		}
		const condition = true
		const p1 = () => {
			return new Promise((resolve, reject) => {
				if (condition) {
					playSound(alarmsound)
					resolve(true)
				} else {
					const error = new Error('Condition is false')
					reject(error)
				}
			}).catch((err) => console.log('Error: ', err))
		}

		const playSound = (sound) => {
			if (sound) {
				var audio = new Audio(sound)
				audio.play().then(() => {
					setTimeout(() => {
						alert('Selected Invoice is sent to inkasso')
					}, 200)
				})
			}
		}
		const isDisabled = computed(() => Store.getters.getIsSelectDisabled)
		const onCellClick = (params) => {
			if (params.column.field === 'select') {
				console.log('select is clicked')
				if (Store.getters.getIsSelectThrottled === false) {
					console.log('isSelectThrottled is false')
					Store.dispatch('setIsSelectDisabled', true).then(() => {
						Store.dispatch('setIsSelectThrottled', true).then(() => {
							if (params.row.inkasso === '*') {
								console.log('is inkasso')
								p1().then((payload) => {
									if (payload) {
										setTransactionFormValues(params)
										return true
									}
								})
							} else {
								setTransactionFormValues(params)
							}
						})
					})
				}
			} else {
				console.log('wait')
			}
		}
		watchEffect(() => {
			console.log(
				'Store.getters.getIsSelectThrottled',
				Store.getters.getIsSelectThrottled,
			)
		})
		watchEffect(() => {
			console.log(
				'Store.getters.getIsSelectDisabled',
				Store.getters.getIsSelectDisabled,
			)
		})

		const { selectedInvoiceState } = useSelectedInvoiceState()
		const { transactionFormState } = useTransactionAddEditForm()
		const onRowDoubleClick = (params) => {
			console.log(params.row)
			setTransactionFormValues(params)
		}
		//-----------------------------------------------
		return {
			sortOptions,
			columns,
			unpaidInvoicesList,
			onCellClick,
			onRowDoubleClick,
			printRows,
			isDisabled,
			loading,
			beginningDate,
			endingDate,
			toggleDays,
			buttonTag,
			buttonType,
		}
	},
}
</script>

<style scoped></style>
