/** @format */

import Vue from 'vue'
import VueCompositionApi, { ref } from '@vue/composition-api'
Vue.use(VueCompositionApi)

const unpaidInvoicesList = ref([])

export default function useUnpaidInvoicesState() {
	return {
		unpaidInvoicesList,
	}
}
