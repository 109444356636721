import Vue from 'vue'
import VueCompositionApi from '@vue/composition-api'
Vue.use(VueCompositionApi)

import useTransactionAddEditForm from '@/_srcv2/pages/transactions/add-transaction/scripts/useTransactionAddEditForm.js'
const { modelTransaction, transactionFormState } = useTransactionAddEditForm()
const setFormValues = (
	paymentType,
	transactionType,
	paymentCreditAccount,
	paymentDebitAccount,
	// selectableCreditAccounts,
	// selectableDebitAccounts,
	invoiceType,
	reportType,
) => {
	console.log('setFormValues is fired')
	// Set types
	modelTransaction.paymentType = paymentType
	modelTransaction.transactionType = transactionType
	// Set Form fields values
	modelTransaction.paymentCreditAccount = paymentCreditAccount
	modelTransaction.paymentDebitAccount = paymentDebitAccount
	// ---------------------------------------------------------------
	modelTransaction.invoiceType = invoiceType
	modelTransaction.reportType = reportType
	// console.log('transactionFormState', transactionFormState)
}
const setFormVisibility = (searchBoxType) => {
	console.log('setFormVisibility is fired')
	if (searchBoxType === 'invoice') {
		transactionFormState.showInvoiceSearchBox = true
		transactionFormState.showCompanySearchBox = false
	}
	if (searchBoxType === 'company') {
		transactionFormState.showInvoiceSearchBox = false
		transactionFormState.showCompanySearchBox = true
	}
	transactionFormState.showCloseButton = true
	transactionFormState.showButtons = false
	transactionFormState.searchType = searchBoxType
}
const getVariables = (variable) => {
	switch (variable) {
		// ********** Collect Payment ******************************
		case 'collect bank':
			setFormValues(
				'collect bank',
				'Customer pays our invoice via bank',
				'1510 Kundfordringar',
				'1930 Bankkonto',
				'sales invoice ',
				'IB',
			)
			setFormVisibility('invoice')
			transactionFormState.isInvoiceNumberDisabled = true
			break
		case 'collect cash':
			setFormValues(
				'collect cash',
				'Customer pays our invoice in cash',
				'1510 Kundfordringar',
				'1910 Kassa',
				'sales invoice ',
				'IB',
			)
			setFormVisibility('invoice')
			transactionFormState.isInvoiceNumberDisabled = true
			break
		case 'collect bank in advance':
			setFormValues(
				'collect bank in advance',
				'Customer pays our invoice via bank in advance',
				'1511 Kundfordringar',
				'1930 Bankkonto',
				'sales invoice ',
				'IB',
			)
			modelTransaction.paymentCredit = ''
			setFormVisibility('company')
			transactionFormState.isInvoiceNumberDisabled = true
			break
		case 'collect cash in advance':
			setFormValues(
				'collect cash in advance',
				'Customer pays our invoice in cash in advance',
				'1511 Kundfordringar',
				'1930 Bankkonto',
				'sales invoice ',
				'IB',
			)
			modelTransaction.paymentCredit = ''
			setFormVisibility('company')
			transactionFormState.isInvoiceNumberDisabled = true
			break
		case 'collect bank reminder fee':
			setFormValues(
				'collect bank reminder fee',
				'Customer pays our reminder fee via bank',
				'3591 Förseningsavgifter, ej moms',
				'1930 Bankkonto',
				'sales invoice ',
				'IB',
			)
			modelTransaction.paymentCredit = ''
			setFormVisibility('company')
			transactionFormState.isInvoiceNumberDisabled = true
			break
		case 'collect cash reminder fee':
			setFormValues(
				'collect cash reminder fee',
				'Customer pays our reminder fee in cash',
				'3591 Förseningsavgifter, ej moms',
				'1930 Bankkonto',
				'sales invoice ',
				'IB',
			)
			modelTransaction.paymentCredit = ''
			setFormVisibility('company')
			transactionFormState.isInvoiceNumberDisabled = true
			break
		case 'get payback via collect bank':
			setFormValues(
				'get payback via collect bank',
				'Supplier is making a refund to us via bank',
				'2440 Leverantörsskulder',
				'1930 Bankkonto',
				'sales invoice ',
				'IB',
			)
			setFormVisibility('company')
			transactionFormState.isInvoiceNumberDisabled = true
			break
		case 'get payback collect cash':
			setFormValues(
				'get payback collect cash',
				'Supplier is making a cash refund to us',
				'2440 Leverantörsskulder',
				'1910 Kassa',
				'sales invoice ',
				'IB',
			)
			setFormVisibility('company')
			transactionFormState.isInvoiceNumberDisabled = true
			break
		// ********** Make Payment ******************************
		case 'pay bank':
			setFormValues(
				'pay bank',
				"We pay supplier's invoice via bank",
				'1930 Bankkonto',
				'2440 Leverantörsskulder',
				'purchase invoice ',
				'UB',
			)
			setFormVisibility('invoice')
			transactionFormState.isInvoiceNumberDisabled = true
			break
		case 'pay cash':
			setFormValues(
				'pay cash',
				"We pay supplier's invoice in cash",
				'1910 Kassa',
				'2440 Leverantörsskulder',
				'purchase invoice ',
				'UB',
			)
			setFormVisibility('invoice')
			transactionFormState.isInvoiceNumberDisabled = true
			break
		case 'pay bank in advance':
			setFormValues(
				'pay bank in advance',
				"We pay supplier's invoice via bank",
				'1930 Bankkonto',
				'2441 Leverantörsskulder',
				'purchase invoice ',
				'UB',
			)
			setFormVisibility('company')
			transactionFormState.isInvoiceNumberDisabled = true
			break
		case 'pay cash in advance':
			setFormValues(
				'pay cash in advance',
				"We pay supplier's invoice in cash",
				'1910 Kassa',
				'2441 Leverantörsskulder',
				'purchase invoice ',
				'UB',
			)
			setFormVisibility('company')
			transactionFormState.isInvoiceNumberDisabled = true
			break
		case 'make payback via bank':
			setFormValues(
				'make payback via bank',
				'We are making a refund to customer via bank',
				'1930 Bankkonto',
				'1511 Kundfordringar',
				'purchase invoice ',
				'UB',
			)
			setFormVisibility('company')
			transactionFormState.isInvoiceNumberDisabled = true
			break
		case 'make payback cash':
			setFormValues(
				'make payback cash',
				'We are making a cash refund to customer',
				'1910 Kassa',
				'1511 Kundfordringar',
				'purchase invoice ',
				'UB',
			)
			setFormVisibility('company')
			transactionFormState.isInvoiceNumberDisabled = true
			break
		// ********** set off records ******************************
		case 'set off credit invoice':
			setFormValues(
				'set off credit invoice',
				'We credit the customer against the "Credit Invoice"',
				'1510 Kundfordringar',
				'1511 Kundfordringar',
				'sales invoice ',
				'SO',
			)
			setFormVisibility('invoice')
			transactionFormState.isInvoiceNumberDisabled = true
			break
		case 'set off advanced payment':
			setFormValues(
				'set off advanced payment',
				'We credit the customer against the "Advanced Payment"',
				'1510 Kundfordringar',
				'1511 Kundfordringar',
				'sales invoice ',
				'SO',
			)
			setFormVisibility('invoice')
			transactionFormState.isInvoiceNumberDisabled = true
			break
		// ********** Free Entry ******************************
		case 'set off records':
			setFormValues(
				'set off records',
				'We set off the debt to the receivable',
				'1510 Kundfordringar',
				'1511 Kundfordringar',
				[
					'1910 Kassa',
					'1930 Bankkonto',
					'1510 Kundfordringar',
					'1511 Kundfordringar',
					'2440 Leverantörsskulder',
					'2441 Leverantörsskulder',
					'3591 Förseningsavgifter, ej moms',
				],
				[
					'1910 Kassa',
					'1930 Bankkonto',
					'1510 Kundfordringar',
					'1511 Kundfordringar',
					'2440 Leverantörsskulder',
					'2441 Leverantörsskulder',
					'3591 Förseningsavgifter, ej moms',
				],
				'setoff ',
			)
			setFormVisibility('company')
			break
		case 'free entry collect payment':
			setFormValues(
				'free entry collect payment',
				'We collect payment',
				'1510 Kundfordringar',
				'1930 Bankkonto',
				[
					'1510 Kundfordringar',
					'1511 Kundfordringar',
					'2440 Leverantörsskulder',
					'2441 Leverantörsskulder',
				],
				['1910 Kassa', '1930 Bankkonto'],
				'sales invoice ',
			)
			setFormVisibility('company')
			break
		case 'free entry make payment':
			setFormValues(
				'free entry make payment',
				'We make payment',
				'1510 Kundfordringar',
				'1930 Bankkonto',
				['1910 Kassa', '1930 Bankkonto'],
				[
					'1510 Kundfordringar',
					'1511 Kundfordringar',
					'2440 Leverantörsskulder',
					'2441 Leverantörsskulder',
				],
				'sales invoice ',
			)
			setFormVisibility('company')
			break
		default:
			window.alert('Something went wrong!')
	}
}
export default function useTransactionAddEditFunctions() {
	return { setFormValues, setFormVisibility, getVariables }
}
