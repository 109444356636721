/** @format */

import Vue from 'vue'
Vue.use(VueCompositionApi)
import VueCompositionApi from '@vue/composition-api'
import Store from '@/store'
import { reactive, toRefs } from '@vue/composition-api'
// Cleave
// eslint-disable-next-line no-unused-vars
import cleave from 'cleave.js'
import useTransactionAddEditFormState from '@/_srcv2/views/pages/accounting/components/transaction-add-edit-form/useAddEditTransactionState'
import numeral from 'numeral'
numeral.defaultFormat('0,0.00')
const { transactionCashOrBank } = useTransactionAddEditFormState()
// -------------------------------------------------------------------------------------------
const transactionFormState = reactive({
	// ------------------------------------------
	paymentDebit: 0,
	paymentCredit: 0,
	paymentDebitInExchange: 0,
	paymentCreditInExchange: 0,
	//  STATE --------------------------------
	isInvoiceNumberDisabled: true,
	showButtons: true,
	showCloseButton: false,
	showCompanySearchBox: false,
	showInvoiceSearchBox: false,
	searchFinished: false,
	searchType: '',
	// ------------------------------------------
	cashBankSetOff: transactionCashOrBank.value, // Where is needed ???
})
// Define the form ----------------------------------------------------------
// const fromFormToDB = (payload) => {
//   return parseInt(parseFloat(payload) * 100)
// }
const calculatePaymentFields = () => {
	console.log('calculatePaymentFields is fired')
	// modelTransaction.paymentCreditInExchange = parseFloat(
	//   modelTransaction.paymentCredit,
	// )
	Store.dispatch(
		'setPaymentCredit',
		cleaveToFloat(modelTransaction.paymentCredit),
	)
	Store.dispatch(
		'setPaymentCreditExchange',
		cleaveToFloat(modelTransaction.paymentCredit),
	)
	// console.log('modelTransaction.paymentCredit', modelTransaction.paymentCredit)
}
const calculatePaymentFieldsExchange = () => {
	console.log('calculatePaymentFieldsExchange is fired')
	let payment = (
		cleaveToFloat(modelTransaction.paymentCreditInExchange) /
		cleaveToFloat(modelTransaction.paymentExchangeRate)
	).toFixed(2)
	let paymentExc = cleaveToFloat(modelTransaction.paymentCreditInExchange)
	let toPayInSek = transactionFormState.paymentCredit
	let toPayInExc = transactionFormState.paymentCreditInExchange
	let isPaid = toPayInExc - paymentExc === 0
	let difference = (payment - toPayInSek).toFixed(2)
	let isDifferencePositive = difference > 0
	// console.log('*********************************************')
	// console.log('payment', payment)
	// console.log('paymentExc', paymentExc)
	// console.log('toPayInSek', toPayInSek)
	// console.log('toPayInExc', toPayInExc)
	// console.log('isPaid', isPaid)
	// console.log('difference', difference)
	// console.log('isDifferencePositive', isDifferencePositive)
	if (isPaid) {
		if (isDifferencePositive) {
			modelTransaction.paymentCredit = toPayInSek
		} else {
			modelTransaction.paymentCredit = payment
		}
		modelTransaction.paymentExchangeDifference = difference
	} else {
		if (isDifferencePositive) {
			modelTransaction.paymentCredit = toPayInSek
			modelTransaction.paymentExchangeDifference = difference
		} else {
			modelTransaction.paymentCredit = payment
			modelTransaction.paymentExchangeDifference = 0
		}
	}
	console.log('modelTransaction.paymentCredit', modelTransaction.paymentCredit)
}
// -----------------------------------------------------------------------------------------
const checkInvoiceIsPaid = (amount) => {
	console.log('checkInvoiceIsPaid is fired')
	// console.log('amount', amount)
	// console.log('typeof amount', typeof amount)
	// console.log(
	//   'transactionFormState.paymentCredit',
	//   transactionFormState.paymentCredit,
	// )
	// console.log(
	//   'typeof transactionFormState.paymentCredit',
	//   typeof parseFloat(transactionFormState.paymentCredit),
	// )
	Store.dispatch(
		'setIsInvoicePaid',
		String(amount === parseFloat(transactionFormState.paymentCredit)),
	)
	// console.log('isInvoicePaid', Store.getters.getIsInvoicePaid)
	// console.log(
	//   'typeof isInvoicePaid.value',
	//   typeof Store.getters.getIsInvoicePaid,
	// )
}
const checkInvoiceIsPaidExchange = (amount) => {
	console.log('checkInvoiceIsPaidExchange is fired')
	Store.dispatch(
		'setIsInvoicePaid',
		String(amount === parseFloat(transactionFormState.paymentCreditInExchange)),
	)
	console.log('isInvoicePaid', Store.getters.getIsInvoicePaid)
}
// -----------------------------------------------------------------------------------------
const cleaveToFloat = (str) => {
	return parseFloat(str.replaceAll(' ', ''))
}
const checkBalancePayment = () => {
	console.log('checkBalancePayment is fired')
	if (
		modelTransaction.paymentType === 'collect bank in advance' ||
		modelTransaction.paymentType === 'collect cash in advance'
	) {
		console.log('check balance is canceled')
	} else {
		let c = cleaveToFloat(modelTransaction.paymentCredit)
		let b = numeral(transactionFormState.paymentCredit).format()
		if (c > transactionFormState.paymentCredit) {
			modelTransaction.paymentCredit = ''
			Store.dispatch('setErrPayment', true)
			alert(`The amount can not be greater than invoice balance
                      Invoice balance: ${b}
                      Amount: ${c}
                      Check the balance and Enter the correct value
                      `)
		} else checkInvoiceIsPaid(c)
	}
}
const checkBalancePaymentExchange = () => {
	console.log('checkBalancePaymentExchange is fired')
	console.log(
		'modelTransaction.paymentCreditInExchange',
		modelTransaction.paymentCreditInExchange,
	)
	console.log(
		'cleaveToFloat(modelTransaction.paymentCreditInExchange)',
		cleaveToFloat(modelTransaction.paymentCreditInExchange),
	)
	let cX = cleaveToFloat(modelTransaction.paymentCreditInExchange)
	let bX = numeral(transactionFormState.paymentCreditInExchange).format()
	if (cX > transactionFormState.paymentCreditInExchange) {
		modelTransaction.paymentCreditInExchange = undefined
		alert(`The amount can not be greater than invoice balance
                      Invoice balance: ${bX}
                      Payment Amount: ${numeral(cX.format())}
                      Check the balance and Enter the correct value
                      `)
	} else {
		checkInvoiceIsPaidExchange(cX)
		calculatePaymentFieldsExchange()
	}
}
// -------------------------------------------------------------------------------------
const modelTransaction = reactive({
	customerID: '', // customerId
	customerOrSupplier: '', // company
	ourCompany: '', // invoiceOurCompany
	invoiceNumber: '', // invoiceNumber
	// ------------------------------------
	paymentID: '',
	transactionType: '',
	paymentType: '',
	paymentDebit: '',
	paymentCredit: '',
	paymentExchangeDifference: '',
	paymentCurrency: '',
	paymentExchangeRate: '',
	paymentDebitInExchange: '',
	paymentCreditInExchange: '',
	paymentDate: '',
	paymentCreditAccount: '',
	paymentDebitAccount: '',
	//  --------------------------------
	invoiceType: '',
	showExchange: false,
	reportType: '',
})
const schemaTransaction = reactive({
	groups: [
		{
			legend: 'Enter Transaction Details',
			id: 'headers',
			featured: true,
			fields: [
				{
					type: 'input',
					inputType: 'text',
					id: 'paymentType',
					label: 'Payment Type',
					model: 'paymentType',
					readonly: true,
					featured: true,
					disabled: true,
					required: false,
				},
				{
					type: 'input',
					inputType: 'text',
					label: 'Company',
					model: 'ourCompany',
					id: 'company_name',
					readonly: true,
					featured: true,
					disabled: true,
					required: true,
				},
				{
					type: 'input',
					inputType: 'text',
					label: 'Customer/Supplier',
					model: 'customerOrSupplier',
					id: 'customerOrSupplier',
					readonly: true,
					featured: true,
					disabled: true,
					required: true,
				},
				{
					type: 'input',
					inputType: 'text',
					label: 'Invoice Number',
					model: 'invoiceNumber',
					id: 'invoice_number',
					readonly: true,
					disabled: true,
					featured: true,
					required: true,
					validator: ['string'],
				},
				{
					type: 'cleave',
					label: 'Transaction Date',
					model: 'paymentDate',
					placeholder: 'Please enter transaction date',
					readonly: true,
					disabled: true,
					featured: true,
					required: true,
					validator: ['required'],
					cleaveOptions: {
						date: true,
						delimiter: '-',
						datePattern: ['Y', 'm', 'd'],
					},
					onValidated: function (model, errors) {
						if (errors.length > 0)
							console.log(
								'Validation error in Transaction Date field! Errors:',
								errors,
							)
						else {
							console.log(
								'modelTransaction.paymentDate',
								modelTransaction.paymentDate,
							)
							transactionFormState.paymentDate = modelTransaction.paymentDate
						}
					},
				},
				// ---------------------------------------------------------------------------
				{
					type: 'cleave',
					label: 'Payment Debit Amount',
					model: 'paymentDebit',
					placeholder: 'Please enter payment debit amount',
					visible(model) {
						return (
							model &&
							model.invoiceType === 'purchase invoice ' &&
							model.showExchange === false
						)
					},
					featured: true,
					disabled: false,
					readonly: false,
					required: true,
					validator: [],
					cleaveOptions: {
						numeral: true,
						numeralThousandsGroupStyle: 'thousand',
						numeralDecimalScale: 2,
						numeralDecimalMark: '.',
					},
					onValidated: function (model, errors) {
						if (errors.length > 0)
							console.log('Validation error in Price field! Errors:', errors)
						else {
							// let d = cleaveToInt100(modelTransaction.paymentDebit) / 100
							// if (d > selectedInvoiceState.balanceTotal) {
							//   modelTransaction.paymentDebit = 0
							//   alert(`The amount can not be greater than invoice balance
							//   Invoice balance: ${selectedInvoiceState.balanceTotal}
							//   Amount: ${d}
							//   Check the balance and Enter the correct value
							//   `)
							// } else checkInvoiceIsPaid(d)
						}
					},
				},
				{
					type: 'cleave',
					label: 'Payment Credit Amount',
					model: 'paymentCredit',
					placeholder: 'Please enter customer payment amount',
					visible(model) {
						return (
							model &&
							model.invoiceType === 'sales invoice ' &&
							model.showExchange === false
						)
					},
					featured: true,
					disabled: false,
					readonly: false,
					required: true,
					validator: ['required'],
					cleaveOptions: {
						numeral: true,
						numeralThousandsGroupStyle: 'thousand',
						numeralDecimalScale: 2,
						numeralDecimalMark: '.',
					},
					onValidated: function (model, errors) {
						if (errors.length > 0) {
							console.log('Validation error in Amount field! Errors:', errors)
							Store.dispatch('setErrPayment', true).then(() => {
								console.log('errPayment = true')
							})
						} else if (modelTransaction.paymentCredit.length < 1) {
							Store.dispatch('setErrPayment', true).then(() => {
								console.log('check length errPayment = true')
							})
						} else {
							console.log('*** payment type: ', model.paymentType)
							if (!model.showExchange) {
								Store.dispatch('setErrPayment', false).then(() => {
									console.log('errPayment = false')
								})
								console.log(
									'************ paymentCredit is validated *************',
								)
								checkBalancePayment()
								calculatePaymentFields()
							}
						}
						// Store.dispatch('setPaymentCredit', model.paymentCredit)
					},
				},
				{
					visible(model) {
						return model && model.showExchange === true
					},
					type: 'select',
					disabled: true,
					label: 'Payment Currency ',
					model: 'paymentCurrency',
					hint: 'Please select payment currency',
					values: ['kr.', '€', '$'],
					featured: true,
					required: true,
					validator: ['string'],
					onValidated: function (model, errors) {
						if (errors.length > 0)
							console.log(
								'Validation error in Payment Currency field! Errors:',
								errors,
							)
						else {
							// localStorage.setItem('stockGroup', model.group)
							// Store.dispatch('setStockGroup', model.group)
						}
					},
				},
				{
					// Only appear if packet value is true
					visible(model) {
						return model && model.showExchange === true
					},
					type: 'cleave',
					label: 'Payment Exchange Rate',
					model: 'paymentExchangeRate',
					placeholder: 'Please enter exchange rate',
					featured: true,
					disabled: false,
					readonly: false,
					required: true,
					validator: ['required'],
					cleaveOptions: {
						numeral: true,
						numeralThousandsGroupStyle: 'thousand',
						numeralDecimalScale: 4,
						numeralDecimalMark: '.',
					},
					onValidated: function (model, errors) {
						if (errors.length > 0) {
							console.log(
								'Validation error in Payment Exchange field! Errors:',
								errors,
							)
							Store.dispatch('setErrExcRate', true).then(() => {
								console.log('errExcRate = true')
							})
						} else {
							calculatePaymentFieldsExchange()
							Store.dispatch('setErrExcRate', false).then(() => {
								console.log('errExcRate = false')
							})
						}
					},
				},
				{
					// Only appear if packet value is true
					visible(model) {
						return (
							model &&
							model.showExchange === true &&
							model.invoiceType === 'purchase invoice'
						)
					},
					type: 'cleave',
					label: 'Payment Debit Amount in Exchange',
					model: 'paymentDebitInExchange',
					// placeholder: 'Please enter our payment amount',
					featured: true,
					disabled: false,
					readonly: false,
					required: true,
					validator: [],
					cleaveOptions: {
						numeral: true,
						numeralThousandsGroupStyle: 'thousand',
						numeralDecimalScale: 2,
						numeralDecimalMark: '.',
					},
					onValidated: function (model, errors) {
						if (errors.length > 0)
							console.log('Validation error in Price field! Errors:', errors)
						else {
							if (model.showExchange) {
								checkBalancePaymentExchange()
							}
						}
					},
				},
				{
					// Only appear if packet value is true
					visible(model) {
						return (
							model &&
							model.showExchange === true &&
							model.invoiceType === 'sales invoice '
						)
					},
					type: 'cleave',
					label: 'Payment Credit Amount in Exchange',
					model: 'paymentCreditInExchange',
					// placeholder: 'Please enter customer payment amount',
					featured: true,
					disabled: false,
					readonly: false,
					required: true,
					validator: ['required'],
					cleaveOptions: {
						numeral: true,
						numeralThousandsGroupStyle: 'thousand',
						numeralDecimalScale: 2,
						numeralDecimalMark: '.',
					},
					onValidated: function (model, errors) {
						if (errors.length > 0) {
							console.log('Validation error in Price field! Errors:', errors)
							Store.dispatch('setErrExcPayment', true).then(() => {
								console.log('errExcPayment = true')
							})
						} else {
							if (modelTransaction.paymentExchangeRate.length < 2) {
								alert('First, you must enter exchange rate')
							} else if (modelTransaction.paymentCreditInExchange.length < 1) {
								Store.dispatch('setErrExcPayment', true).then(() => {
									console.log('check length errExcPayment = true')
								})
							} else {
								if (model.showExchange) {
									Store.dispatch('setErrExcPayment', false).then(() => {
										console.log('errExcPayment = false')
									})
								}
								console.log(
									'************ paymentCreditExchange is validated *************',
								)
								checkBalancePaymentExchange()
								calculatePaymentFieldsExchange()
							}
						}
					},
				},
				{
					// Only appear if packet value is true
					visible(model) {
						return (
							model &&
							model.showExchange === true &&
							model.invoiceType === 'sales invoice '
						)
					},
					type: 'cleave',
					label: 'Payment Exchange Difference',
					model: 'paymentExchangeDifference',
					placeholder: 'Please enter exchange difference amount',
					featured: true,
					disabled: true,
					readonly: true,
					required: true,
					// dependOn: [{ model: 'showExchange', value: true }],
					validator: [],
					cleaveOptions: {
						numeral: true,
						numeralThousandsGroupStyle: 'thousand',
						numeralDecimalScale: 2,
						numeralDecimalMark: '.',
					},
					onValidated: function (model, errors) {
						if (errors.length > 0)
							console.log('Validation error in Price field! Errors:', errors)
					},
				},
				{
					type: 'input',
					inputType: 'text',
					label: 'Transaction Type',
					model: 'transactionType',
					id: 'transactionType',
					readonly: true,
					featured: true,
					disabled: true,
					required: true,
				},
				{
					type: 'input',
					inputType: 'text',
					label: 'Payment Credit Account',
					model: 'paymentCreditAccount',
					id: 'paymentCreditAccount',
					readonly: true,
					featured: true,
					disabled: true,
					required: true,
				},
				{
					type: 'input',
					inputType: 'text',
					label: 'Payment Debit Account ',
					model: 'paymentDebitAccount',
					id: 'paymentDebitAccount',
					readonly: true,
					featured: true,
					disabled: true,
					required: true,
				},
				{
					type: 'input',
					inputType: 'text',
					label: 'Report Type',
					model: 'reportType',
					id: 'reportType',
					default: 'IB',
					readonly: true,
					featured: true,
					disabled: true,
					required: true,
				},
			],
		},
	],
})
const formOptionsTransaction = reactive({
	validateAfterLoad: true,
	validateAfterChanged: true,
	validateAsync: true,
	fieldIdPrefix: 'transactionForm',
})

export default function useTransactionAddEditForm() {
	return {
		modelTransaction,
		schemaTransaction,
		formOptionsTransaction,
		...toRefs(modelTransaction),
		transactionFormState,
		...toRefs(transactionFormState),
	}
}
