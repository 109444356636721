<template>
	<div>
		<div class="margin-bottom-20">
			<div v-if="showExchange === false">
				<b-button class="is-info" @click="onExchange">
					Exchange Transaction
				</b-button>
			</div>
			<div v-if="showExchange === true">
				<b-button class="is-primary" @click="onDomesticCurrency">
					Domestic Currency Transaction
				</b-button>
			</div>
		</div>
		<form id="formTransaction">
			<vue-form-generator
				@validated="onFormValidated"
				:schema="schemaTransaction"
				:model="modelTransaction"
				:options="formOptionsTransaction"
			>
			</vue-form-generator>
		</form>
		<div class="margin-bottom-30">
			<span v-if="modelTransaction.showExchange">
				<b-button
					:disabled="toggleSaveButton"
					class="is-success"
					type="submit"
					@click="onSave"
					>Save Exchange Payment
				</b-button>
			</span>
			<span v-if="!modelTransaction.showExchange">
				<b-button
					:disabled="toggleSaveButton"
					class="is-success"
					type="submit"
					@click="onSave"
					>Save Payment
				</b-button>
			</span>
			<span class="margin-left-20">
				<b-button class="is-danger" @click="onCancel">Cancel </b-button>
			</span>
		</div>
	</div>
</template>

<script>
import useTransactionAddEditForm from '@/_srcv2/pages/transactions/add-transaction/scripts/useTransactionAddEditForm.js'
import useSelectedInvoiceState from '@/_srcv2/pages/transactions/add-transaction/scripts/useSelectedInvoiceState.js'
import { ref, onMounted, computed } from '@vue/composition-api'
import Store from '@/store'
import { useMutation } from '@vue/apollo-composable'
import AddPaymentMutation from '@/_srcv2/pages/transactions/add-transaction/graphql/AddPaymentMutation.graphql'
import AddPaymentWithoutInvoiceMutation from '@/_srcv2/pages/transactions/add-transaction/graphql/AddPaymentWithoutInvoiceMutation.graphql'
import useUnpaidInvoicesState from '@/_srcv2/pages/transactions/add-transaction/scripts/useUnpaidInvoicesState.js'

export default {
	name: 'TransactionForm',
	setup() {
		// todo --------------------------------------------------------------------
		const { unpaidInvoicesList } = useUnpaidInvoicesState()

		const onExchange = () => (showExchange.value = true)
		const onDomesticCurrency = () => (showExchange.value = false)
		// import model, schema and form options
		const {
			modelTransaction,
			schemaTransaction,
			formOptionsTransaction,
			// show exchange ----------------------------------
			showExchange,
			// Get form state ----------------------------------
			transactionFormState,
			// -------------------------------------------------
		} = useTransactionAddEditForm()
		//----------------------------------------------------------
		const { selectedInvoiceState } = useSelectedInvoiceState()
		//----------------------------------------------------------
		// Set default state form model
		const setInitialState = () => {
			console.log(
				'********************* set initial state is fired ******************************',
			)
			Store.dispatch('setIsSelectDisabled', false)
			Store.dispatch('setIsSelectThrottled', false)
			modelTransaction.paymentID = ''
			modelTransaction.paymentExchangeDifference = 0
			modelTransaction.paymentExchangeRate = ''
			modelTransaction.paymentDate = Store.getters.getTransactionDate
			//  -------------------------------------------------------------------
			modelTransaction.customerID = selectedInvoiceState.customerId
			modelTransaction.customerOrSupplier = selectedInvoiceState.company
			modelTransaction.invoiceNumber = selectedInvoiceState.invoiceNumber
			modelTransaction.ourCompany = selectedInvoiceState.invoiceOurCompany
			// ---------------------------------------------------------------------
			modelTransaction.paymentCurrency = selectedInvoiceState.invoiceCurrency
			if (selectedInvoiceState.transactionType === 'sales invoice ') {
				modelTransaction.paymentCredit = selectedInvoiceState.balanceTotalDb
				transactionFormState.paymentCredit = selectedInvoiceState.balanceTotalDb
				modelTransaction.paymentDebit = 0
				transactionFormState.paymentDebit = 0
				modelTransaction.paymentDebitInExchange = 0
				transactionFormState.paymentDebitInExchange = 0
				modelTransaction.paymentCreditInExchange =
					selectedInvoiceState.balanceTotalInExchangeDb
				transactionFormState.paymentCreditInExchange =
					selectedInvoiceState.balanceTotalInExchangeDb
			} else if (selectedInvoiceState.transactionType === 'purchase invoice ') {
				modelTransaction.paymentDebit = selectedInvoiceState.balanceTotalDb
				modelTransaction.paymentCredit = 0
				modelTransaction.paymentDebitInExchange =
					selectedInvoiceState.balanceTotalInExchangeDb
				modelTransaction.paymentCreditInExchange = 0
			}
			showExchange.value = selectedInvoiceState.isInExchange
			console.log(
				'********************* set initial state is ended ******************************',
			)
		}
		const fromFormToDB = (payload) => {
			return parseInt(parseFloat(payload) * 100)
		}
		const transactionObject = computed(() => {
			return {
				invoice_number: modelTransaction.invoiceNumber,
				payment_type: modelTransaction.paymentType,
				payment_date: modelTransaction.paymentDate,
				payment_debit: fromFormToDB(modelTransaction.paymentDebit) || 0,
				payment_credit: fromFormToDB(Store.getters.getPaymentCredit),
				payment_currency: modelTransaction.paymentCurrency,
				payment_exchange_rate:
					parseFloat(modelTransaction.paymentExchangeRate) || 1,
				payment_debit_in_exchange:
					fromFormToDB(modelTransaction.paymentDebitInExchange) || 0,
				payment_credit_in_exchange: fromFormToDB(
					Store.getters.getPaymentCreditExchange,
				),
				payment_exchange_difference: fromFormToDB(
					modelTransaction.paymentExchangeDifference,
				),
				payment_credit_account: modelTransaction.paymentCreditAccount,
				payment_debit_account: modelTransaction.paymentDebitAccount,
				our_company: modelTransaction.ourCompany,
				customer_id: selectedInvoiceState.customerId,
				report_type: modelTransaction.reportType,
			}
		})
		// * -----------------------------------------------------------------------------
		onMounted(() => {
			// todo set form default state
			setInitialState()
		})
		// * --------------------------------------------------------------------------------
		const addTransaction = (expression) => {
			switch (expression) {
				case 'collect bank in advance':
					addPaymentWithoutInvoice()
					break
				case 'collect bank reminder fee':
					addPaymentWithoutInvoice()
					break
				case 'get payback via collect bank':
					addPaymentWithoutInvoice()
					break
				case 'collect cash in advance':
					addPaymentWithoutInvoice()
					break
				case 'collect cash reminder fee':
					addPaymentWithoutInvoice()
					break
				case 'get payback collect cash':
					addPaymentWithoutInvoice()
					break
				default:
					addPayment()
			}
		}
		// todo ---------------------------------------------------------------------
		function removeObjectByKey(key, value, array) {
			const indexToRemove = array.findIndex((obj) => obj[key] === value)
			if (indexToRemove !== -1) {
				array.splice(indexToRemove, 1) // ? Remove 1 element at the found index
				return true // ? Indicate successful removal
			} else {
				return false // ? Indicate that the object was not found
			}
		}
		const numberToDB = (payload) => {
			return parseFloat(payload.replace(',', '').replace(' ', '')) * 100
		}
		const keyToRemove = ref('invoiceNum')
		const valueToRemove = computed(() => modelTransaction.invoiceNumber)
		const myArray = computed(() => unpaidInvoicesList.value)
		const balance = computed(() =>
			numberToDB(selectedInvoiceState.balanceTotal),
		)
		const payment = computed(
			() => parseFloat(modelTransaction.paymentCredit) * 100,
		)
		// todo --------------------------------------------------------------------
		const onSave = () => {
			if (
				modelTransaction.paymentType === 'collect bank' ||
				modelTransaction.paymentType === 'collect cash'
			) {
				// console.log('keyToRemove', keyToRemove.value)
				// console.log('valueToRemove', valueToRemove.value)
				// console.log('myArray', myArray.value)
				// console.log('balance', balance.value)
				// console.log('payment', payment.value)
				// ? ----------------------------------------------------------------------
				// * Remove paid invoice from list *******************************************************
				if (payment.value === balance.value) {
					let wasRemoved = removeObjectByKey(
						keyToRemove.value,
						valueToRemove.value,
						myArray.value,
					)
					if (wasRemoved) {
						console.log(
							`Object with key '${keyToRemove.value}' and value '${valueToRemove.value}' was removed.`,
						)
						console.log(myArray) // Output: [{ id: 1, name: "Alice" }, { id: 3, name: "Charlie" }]
					} else {
						console.log(
							`Object with key '${keyToRemove.value}' and value '${valueToRemove.value}' not found in the array.`,
						)
					}
				}
			}
			Store.dispatch('setErrExcRate', true)
			Store.dispatch('setErrExcPayment', true)
			addTransaction(modelTransaction.paymentType)
			// * Set search finished to open next form ***************************************
			transactionFormState.searchFinished = false
		}
		const onCancel = () => {
			Store.dispatch('setErrExcRate', true)
			Store.dispatch('setErrExcPayment', true)
			// * Show Search Component *******************************************************
			transactionFormState.searchType === 'invoice'
				? (transactionFormState.showInvoiceSearchBox = true)
				: (transactionFormState.showCompanySearchBox = false)
			transactionFormState.showCloseButton = true
			// * Set search finished to open next form ***************************************
			transactionFormState.searchFinished = false
		}
		// * --------------------------------------------------------------------------------
		const {
			mutate: addPayment,
			onDone,
			onError,
		} = useMutation(AddPaymentMutation, () => ({
			variables: {
				payment: transactionObject.value,
				our_company: modelTransaction.ourCompany,
				invoice_number: modelTransaction.invoiceNumber,
				is_invoice_paid: Store.getters.getIsInvoicePaid,
			},
		}))
		// Create alarm for mutations
		onDone((result) => {
			let paidInvoiceNumber =
				result.data.insert_payments.returning[0].invoice_number
			let isInvoiceTotallyPaid =
				result.data.update_document_transactions.returning[0].is_invoice_paid
			const paymentAmountForPopup = ref(0)
			if (modelTransaction.invoiceType === 'sales invoice ') {
				paymentAmountForPopup.value = modelTransaction.paymentCredit
			} else if (
				modelTransaction.transactionTypeInvoice === 'purchase invoice'
			) {
				paymentAmountForPopup.value = modelTransaction.paymentDebit
			}
			let popUpMsg = `
      The transaction is saved
      Invoice Number: ${paidInvoiceNumber}
      Payment Amount: ${paymentAmountForPopup.value}
      Is Totally Paid: ${isInvoiceTotallyPaid}
      `
			alert(popUpMsg)
			console.log(result)
		})
		onError(() => {
			const errMessage =
				'Something went wrong. Item can not be saved check the data you have entered!!!'
			alert('Error', errMessage)
		})
		// * --------------------------------------------------------------------------------
		const {
			mutate: addPaymentWithoutInvoice,
			onDone: onDoneWithoutInvoice,
			onError: onErrorWithoutInvoice,
		} = useMutation(AddPaymentWithoutInvoiceMutation, () => ({
			variables: {
				payment: transactionObject.value,
			},
		}))
		// Create alarm for mutations
		onDoneWithoutInvoice((result) => {
			let paidInvoiceNumber =
				result.data.insert_payments.returning[0].invoice_number
			const paymentAmountForPopup = ref(0)
			if (modelTransaction.invoiceType === 'sales invoice ') {
				paymentAmountForPopup.value = modelTransaction.paymentCredit
			} else if (
				modelTransaction.transactionTypeInvoice === 'purchase invoice'
			) {
				paymentAmountForPopup.value = modelTransaction.paymentDebit
			}
			let popUpMsg = `
      The transaction is saved
      Invoice Number: ${paidInvoiceNumber}
      Payment Amount: ${paymentAmountForPopup.value}
      `
			alert(popUpMsg)
			console.log(result)
		})
		onErrorWithoutInvoice(() => {
			const errMessage =
				'Something went wrong. Item can not be saved check the data you have entered!!!'
			alert('Error', errMessage)
		})
		// **************************************************************************
		const toggleSaveButton = ref(true)
		onMounted(() => {
			toggleSaveButton.value = true
		})
		const onFormValidated = (isValid) => {
			if (isValid) {
				toggleSaveButton.value = false
			} else {
				toggleSaveButton.value = true
			}
		}
		return {
			modelTransaction,
			schemaTransaction,
			formOptionsTransaction,
			onExchange,
			onDomesticCurrency,
			showExchange,
			onFormValidated,
			toggleSaveButton,
			onSave,
			onCancel,
		}
	},
}
</script>

<style scoped>
.header-tag {
	font-weight: bold;
}
.color-orange {
	color: orange;
}
.color-blue {
	color: blue;
}
.color-red {
	color: red;
}
.color-green {
	color: green;
}
</style>
