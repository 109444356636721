import { reactive, toRefs } from '@vue/composition-api'
import Vue from 'vue'
import VueCompositionApi from '@vue/composition-api'
Vue.use(VueCompositionApi)

const state = reactive({
	transactionMethod: '',
	transactionCashOrBank: '',
	showAddEditTransactionForm: false,
	keySearchInvoiceResultList: 1,
	keyFormTransaction: 1,
	isInvoicePaid: false,
})
export default function useTransactionAddEditFormState() {
	return { ...toRefs(state) }
}
