import { reactive } from '@vue/composition-api'

// To share the state between components write thw following three lines
// Otherwise each component creates a new instance of the reactive state object that can not share the state
import Vue from 'vue'
import VueCompositionApi from '@vue/composition-api'
Vue.use(VueCompositionApi)

const selectedInvoiceState = reactive({
	invoiceOurCompany: '', // ourCompany
	customerId: '', // customerID
	company: '', // customerOrSupplier
	invoiceNumber: '', // invoiceNumber
	transactionType: '',
	invoiceType: '',
	isCredit: false,
	isInExchange: false,
	invoiceCurrency: '',
	// -----------------------------
	invoiceTotal: 0,
	paymentTotal: 0,
	balanceTotal: 0,
	// -----------------------------
	invoiceTotalInExchange: 0,
	paymentTotalInExchange: 0,
	balanceTotalInExchange: 0,
	// -----------------------------
	balanceTotalDb: 0,
	balanceTotalInExchangeDb: 0,
})
export default function useSelectedInvoiceState() {
	return { selectedInvoiceState }
}
